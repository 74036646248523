import React from 'react';
// import Link from '@material-ui/core/Link';
// import { makeStyles } from '@material-ui/core/styles';
// import ChartSearchForm from './ChartSearchForm';
import Iframe from 'react-iframe';
import Title from './Title';

// const useStyles = makeStyles((theme) => ({
//     seeMore: {
//       marginTop: theme.spacing(3),
//     },
//   }));

  const Rchart = props => {
    console.log('RENDERING CHART');
    return (
        <React.Fragment>
          <Title>Current Chart</Title>
          <Iframe 
            key={props.chartfile}
            url={props.chartfileurl}
            width="1000px"
            height="600px"
            scrolling="no"
            id="rchart"
            display="initial"
            position="relative"
              />
       </React.Fragment>

    //   <section className="ingredient-list">
    //     <h2>Loaded Ingredients</h2>
    //     <ul>
    //       {props.ingredients.map(ig => (
    //         <li key={ig.id} onClick={props.onRemoveItem.bind(this, ig.id)}>
    //           <span>{ig.title}</span>
    //           <span>{ig.amount}x</span>
    //         </li>
    //       ))}
    //     </ul>
    //   </section>
    );
  };
  
  export default Rchart;

//   export default function Rchart() {
//     const classes = useStyles();
//     const [chartFile, setChartfile] = useState([ChartSearchForm.userChart]);

//     return (
//       <React.Fragment>
//         {/* <Title>Current Chart</Title> */}
//         <Iframe url={chartFile}
//           width="1000px"
//           height="600px"
//           id="rchart"
//           display="initial"
//           position="relative"
//       />
//       </React.Fragment>
//     );
//   }